
import { defineComponent, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { hideModal } from "@/core/helpers/dom";
import useOrders from "@/composables/useOrders";
import { CustomersForLookUpFilter } from "@/models";
import { IOrderCustomerLookupTableDto } from "@/shared/service-proxies/service-proxies";

export default defineComponent({
  components: {
    Datatable,
  },
  emits: ["selectCustomer"],
  setup(props, { emit }) {
    const { getCustomersForLookUp, customersForLookUp } = useOrders();

    const loading = ref<boolean>(true);
    const formRef = ref<null | HTMLFormElement>(null);
    const pickCustomerModalRef = ref<null | HTMLElement>(null);

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "First name",
        key: "firstname",
        sortable: true,
      },
    ]);
    const tableData = ref<Array<IOrderCustomerLookupTableDto>>([]);

    const defaultCustomerFilter = ref({
      filter: null,
      sorting: null,
      skipCount: 0,
      maxResultCount: 10000,
      cancelToken: null,
    } as unknown as CustomersForLookUpFilter);

    onMounted(async () => {
      await getCustomersData();
    });

    const getCustomersData = async () => {
      loading.value = true;

      await getCustomersForLookUp(
        defaultCustomerFilter.value as unknown as CustomersForLookUpFilter
      );

      tableData.value = customersForLookUp.value;

      loading.value = false;
    };

    const selectCustomer = (id: string) => {
      const customer = customersForLookUp.value.find((c) => c.id === id);

      emit("selectCustomer", customer);
      hideModal(pickCustomerModalRef.value);
    };

    return {
      tableHeader,
      tableData,
      loading,
      formRef,
      pickCustomerModalRef,
      defaultCustomerFilter,
      selectCustomer,
      getCustomersData,
    };
  },
});


import { defineComponent, ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PickCustomerModal from "@/components/modals/forms/PickCustomerModal.vue";
import useOrders from "@/composables/useOrders";
import useProducts from "@/composables/useProducts";
import {
  IGetOrderForViewDto,
  GetOrderDetailForViewDto,
  OrderDetailDto,
  OrderDto,
  CustomerDto,
  IOrderCustomerLookupTableDto,
} from "@/shared/service-proxies/generated-proxies";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { toast } from "@/core/services/ToastService";
import { ProductsFilter } from "@/models";
import { formatCurrency } from "@/core/services/HelperServices";
import useCustomers from "@/composables/useCustomers";
import { Channel } from "@/store/enums/StoreEnums";

export default defineComponent({
  components: { PickCustomerModal },
  setup() {
    const { order, getOrder, updateOrCreateOrder } = useOrders();
    const {
      products,
      getProducts,
      productUnitsLookUp,
      getProductUnitsForLookUp,
    } = useProducts();
    const { getCustomer, customer } = useCustomers();
    const route = useRoute();
    const router = useRouter();
    const saving = ref(false);
    const store = useStore();
    const dataId = computed(() => route.query.id);
    const customerId = computed(() => route.query.customerId);
    const selectedCustomer = ref<CustomerDto>();

    const orderStatus = [
      {
        name: "Pending",
        value: 0,
      },
      {
        name: "Delivered",
        value: 1,
      },
      {
        name: "Cancelled",
        value: 2,
      },
    ];
    const orderPaymentMethod = [
      {
        name: "Cash",
        value: 0,
      },
      {
        name: "Credit",
        value: 1,
      },
      {
        name: "POS",
        value: 2,
      },
      {
        name: "Transfer",
        value: 3,
      },
    ];
    const orderPaymentStatus = [
      {
        name: "Outstanding",
        value: 0,
      },
      {
        name: "Paid",
        value: 1,
      },
    ];

    const breadcrumbs = ref({});

    const defaultOrderDetail = ref({
      productUnitPrice: 0,
      productTotalPrice: 0,
      productQuantity: 0,
      orderId: "",
      productId: "",
      productUnitId: "",
      id: "",
    }) as unknown as OrderDetailDto;

    const orderDetail = ref<OrderDetailDto>(defaultOrderDetail);

    const totalOrderDetailPrice = ref<string>("");

    const defaultData = ref({
      order: {
        orderTotalPrice: "",
        orderDate: "",
        orderNumber: "0",
        orderStatus: "",
        orderPaymentStatus: "",
        orderPaymentMethod: "",
        orderTotalQuantity: "",
        validationCode: "",
        isValidated: "",
        agentId: "",
        customerId: "",
        deliveryAgentId: "",
        id: "",
      } as unknown as OrderDto,
      agentFirstname: "",
      customerFirstName: "",
      orderChannel: "",
      orderDetails: [] as unknown as GetOrderDetailForViewDto[],
      deliveryAgentName: "",
    }) as unknown as IGetOrderForViewDto;

    let formData = ref<IGetOrderForViewDto>(defaultData);

    const submit = async () => {
      saving.value = true;

      const response = await updateOrCreateOrder(
        formData.value as unknown as IGetOrderForViewDto
      );
      const error = store.getters.getErrors;

      if (response) {
        Swal.fire({
          text: dataId.value
            ? "Order updated successfully"
            : "Order created successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Okay!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "orders" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
      saving.value = false;
    };

    onMounted(async () => {
      await getProducts({
        filter: "",
        skipCount: 0,
        isActiveFilter: -1,
        maxResultCount: 1000,
      } as unknown as ProductsFilter);

      await getProductUnitsForLookUp();

      if (customerId.value) {
        await getCustomer(customerId.value as string);
        formData.value.customerFirstName = customer.value.customer.firstName;
        formData.value.order.customerId = customer.value.customer.id;
        formData.value.orderChannel =
          Channel.ContactCenter as unknown as string;
        // console.log(customer.value);
      } else {
        formData.value.orderChannel = Channel.Web as unknown as string;
      }
      if (dataId.value) {
        breadcrumbs.value = {
          title: "Edit order",
          breadcrumbs: ["Order", "Edit"],
        };
        // setCurrentPageBreadcrumbs("Edit Order", ["Order", "Edit"]);

        await getOrder(dataId.value as string);

        if (order.value) {
          formData.value = { ...order.value };
        }
      } else {
        breadcrumbs.value = {
          title: "Create order",
          breadcrumbs: ["Order", "Create"],
        };
        // setCurrentPageBreadcrumbs("Create Order", ["Order", "Create"]);
      }

      computeOrderDetailsPrice();
    });

    //Methods
    const addOrderdetail = () => {
      if (
        !orderDetail.value.productUnitId ||
        !orderDetail.value.productId ||
        !orderDetail.value.productQuantity
      ) {
        toast.error("All inputs are required");
        return;
      }

      if (orderDetail.value.productQuantity < 1) {
        toast.error("Invalid product quantity");
        return;
      }

      const product = products.value
        .find((p) => p.product.id === orderDetail.value.productId)
        ?.product.toJSON();

      const productUnit = productUnitsLookUp.value
        .find((p) => p.id === orderDetail.value.productUnitId)
        ?.toJSON();

      //Re-calculate product unit details
      orderDetail.value.productUnitPrice = product.unitPrice;
      orderDetail.value.productTotalPrice =
        product.unitPrice * orderDetail.value.productQuantity;

      formData.value.orderDetails?.push({
        orderDetail: { ...orderDetail.value },
        productName: product.productName,
        productUnitName: productUnit?.displayName,
      } as unknown as GetOrderDetailForViewDto);

      orderDetail.value = {} as unknown as OrderDetailDto;
      computeOrderDetailsPrice();
    };

    const deleteOrderDetail = (id: string) => {
      formData.value.orderDetails = formData.value.orderDetails?.filter(
        (el) => el.orderDetail.id !== id
      );
      computeOrderDetailsPrice();
    };

    const computeOrderDetailsPrice = () => {
      let gross = 0;
      let totalQuantity = 0;
      formData.value.orderDetails?.forEach((el) => {
        gross += el.orderDetail.productTotalPrice;
        totalQuantity += el.orderDetail.productQuantity;
      });

      totalOrderDetailPrice.value = formatCurrency(gross);
      formData.value.order.orderTotalPrice = gross;
      formData.value.order.orderTotalQuantity = totalQuantity;
    };

    const removeCustomer = () => {
      formData.value.order.customerId = "";
      formData.value.customerFirstName = "";
    };

    const selectCustomer = (customer: IOrderCustomerLookupTableDto) => {
      formData.value.order.customerId = customer.id;
      formData.value.customerFirstName = customer.displayName;
    };

    return {
      orderDetail,
      products,
      productUnitsLookUp,
      formData,
      saving,
      route,
      order,
      orderStatus,
      orderPaymentMethod,
      orderPaymentStatus,
      totalOrderDetailPrice,
      selectedCustomer,
      selectCustomer,
      removeCustomer,
      submit,
      deleteOrderDetail,
      addOrderdetail,
      formatCurrency,
      breadcrumbs,
    };
  },
});
